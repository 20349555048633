import {
  GrowthBook,
  GrowthBookProvider as GBP,
  Result,
} from '@growthbook/growthbook-react'
import { ReactNode, useEffect } from 'react'

import experimentViewed from 'hooks/useAnalytics/events/experimentViewed'

import getAnonymousId from 'utils/getAnonymousId'
import { getExperimentViewed, markExperimentAsViewed } from 'utils/localStorage'

interface ExperimentMeta {
  key: string
  name: string
}

interface Experiment {
  variations: any[]
  key: string
  coverage: number
  weights: number[]
  hashAttribute: string
  meta: ExperimentMeta[]
  name: string
  phase: string
  seed: string
  hashVersion: number
}

interface DetailedExperimentResult {
  key: string
  featureId: string
  inExperiment: boolean
  hashUsed: boolean
  variationId: number
  value: any
  hashAttribute: string
  hashValue: string
  stickyBucketUsed: boolean
  name: string
  bucket: number
}

interface FeatureResult {
  value: any
  on: boolean
  off: boolean
  source: string
  ruleId: string
  experiment: Experiment
  experimentResult: DetailedExperimentResult
}

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_FEATURE_TOGGLE_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_FEATURE_TOGGLE_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
  remoteEval: true,
  trackingCallback: (experiment, result: Result<any>) => {
    const value = getExperimentViewed(experiment.key)

    const { experimentResult } = (result as unknown as FeatureResult) || {}
    const { name: variationName, key: variationKey } = experimentResult || {}

    const hasLocalStorageValue = !!value
    const isSameVariant = hasLocalStorageValue && value === variationKey

    if (!hasLocalStorageValue || !isSameVariant) {
      experimentViewed({
        experimentId: experiment.key,
        variationId: variationKey,
        variationName: variationName,
      })

      markExperimentAsViewed(experiment.key, result.key)
    }
  },
})

const ExperimentTracker = () => {
  return null
}

const GrowthBookProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    if (!growthbook.getAttributes().anonymous_id) {
      const anonymousId = getAnonymousId()
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        anonymous_id: anonymousId,
      })
    }

    growthbook.init({ streaming: true })
  }, [])

  return (
    <GBP growthbook={growthbook}>
      <>
        <ExperimentTracker />
        {children}
      </>
    </GBP>
  )
}

export default GrowthBookProvider
